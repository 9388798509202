<template>
  <!-- mp创意 -->
  <div class="paddbox autobox">
    <div v-for="(i, indexs) in mpAdcreativesDto">
      <el-card>
        <el-form label-position="right" label-width="120px">
          <el-form-item label="动态创意类型:">
            <el-radio-group v-model="i.creativeType">
              <el-radio-button :label="'DYNAMIC_CREATIVE_TYPE_COMMON'">手动指定</el-radio-button>
              <el-radio-button :label="'DYNAMIC_CREATIVE_TYPE_PROGRAM'">自动匹配</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-table 
              :data="i.videoDtos"
              border
              style="width: 100%"
            >
              <el-table-column prop="accountId" label="账户id" width="120">
              </el-table-column>
              <el-table-column label="创意形式" width="120" v-if="i.creativeType=='DYNAMIC_CREATIVE_TYPE_COMMON'">
                <template #default="scope">
                  <el-radio-group
                    v-model="scope.row.adcreativeTemplateId"
                    size="mini"
                  >
                    <el-radio-button :label="721"
                      >竖版视频 9:16</el-radio-button
                    >
                    <el-radio-button :label="618">常规视频 4:3</el-radio-button>
                    <el-radio-button :label="720"
                      >横版视频 16:9</el-radio-button
                    >
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column label="创意视频">
                <template #default="scope">
                  <div>
                    <div>
                      <div class="cursor ldTitle" @click="openNewWindows">
                        新增标题组
                      </div>
                      <div
                        class="cursor ldTitle"
                        @click="selectTitleFun(indexs,scope.$index)"
                      >
                        添加视频
                      </div>
                      <div
                        class="cursor ldTitle"
                        @click="getIndexs(indexs)"
                      >
                        获取定位
                      </div>
                    </div>
                    <el-dialog v-model="scope.row.wt" width="1600px">
                      <selectMPVideo
                        @getTitleId="getTitleId"
                        @getSelectSucaiVideo="getSelectSucaiVideo"
                        :waiChangeVideo="scope.row.vidslist"
                      ></selectMPVideo>
                    </el-dialog>
                    <div>
                      <showVideo
                        :index="scope.$index"
                        :area="scope.row.area"
                        :sucaiData="scope.row.vidslist"
                        @getsucaiDataChange="getsucaiDataChange"
                      >
                      </showVideo>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item>
            <el-table
              :data="i.textDtos"
              border
              style="width: 100%"
            >
              <el-table-column prop="accountId" label="账户id" width="120">
              </el-table-column>
              <el-table-column label="文案（1-30字）">
                <template #default="scope">
                  <div>
                    <el-button type="primary" @click="addWenanFun(scope,indexs)"
                      >新增一条文案</el-button
                    >
                  </div>
                  <div
                    v-for="(item, index) in scope.row.texts"
                    class="wenanDIV flex"
                  >
                    <div>
                      <el-input
                        placeholder="请输入文案"
                        v-model="item.text"
                        clearable
                      >
                      </el-input>
                    </div>

                    <div>
                      <el-button
                        type="danger"
                        icon="el-icon-minus"
                        circle
                        @click="deleteWenanFun(scope, index,indexs)"
                      ></el-button>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="品牌标识" v-if="!automaticList.sph">
            <div class="sp" @click="brandLogoViewFun(indexs)">
              {{
                i.mpBrandDto.state
                  ? '已配置品牌'
                  : '请配置品牌标识！！！'
              }}
            </div>
          </el-form-item>
          <el-form-item
            label="品牌："
            v-if="i.mpBrandDto.state && !automaticList.sph"
          >
            <div class="flex">
              <div>
                <img
                  class="img"
                  :src="i.mpBrandDto.brandLogo.image"
                  alt=""
                />
              </div>
              <div>{{ i.mpBrandDto.brandLogo.brandName }}</div>
            </div>
          </el-form-item>

          <el-form-item label="浮层卡片" v-if="automaticList.sph">
            <div class="sp" @click="FuCengCardLogoViewFun(indexs)">
              {{
                i.FuCengCard.state
                  ? '已配置浮层卡片'
                  : '请配置浮层卡片！！！'
              }}
            </div>
          </el-form-item>
          <el-form-item
            label="卡片："
            v-if="i.FuCengCard.state && automaticList.sph"
          >
            <div class="flex">
              <div>
                <img
                  class="img"
                  :src="i.FuCengCard.FuCengCardLogo.image"
                  alt=""
                />
              </div>
              <div>
                {{ i.FuCengCard.FuCengCardLogo.brandName }}
              </div>
            </div>
          </el-form-item>

          <el-form-item label="品牌按钮:">
            <el-select
              @click="selectbuttonTextFun(indexs)"
              v-model="i.mpBrandDto.buttonText"
              filterable
              placeholder="请选择品牌按钮"
            >
              <el-option
                v-for="item in i.mpBrandDto.buttonTextData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品牌描述信息:" v-if="automaticList.pyq">
            <el-input
              placeholder="请输入品牌描述信息"
              v-model="i.mpBrandDto.brandDesc"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item v-if="automaticList.sph">
            <el-table
              :data="i.promotedObjects"
              border
              style="width: 100%"
            >
              <el-table-column prop="accountId" label="账户id" width="100">
              </el-table-column>
              <el-table-column label="视频号">
                <template #default="scope">
                  <el-select
                    @click="selectPromotedObjectsGetV3Fun(scope,indexs)"
                    v-model="scope.row.userName"
                    filterable
                    placeholder="请选择视频号"
                  >
                    <el-option
                      v-for="item in scope.row.userNameData"
                      :key="item.promotedObjectId"
                      :label="item.promotedObjectName"
                      :value="item.promotedObjectId"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>

          <el-form-item label="链接选择:">
            <el-radio-group
              v-model="i.isFriendsTextOrImageText"
              size="medium"
            >
              <el-radio-button :label="0">朋友圈文字链</el-radio-button>
              <el-radio-button disabled :label="1">图文链接</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="图文链接"
            v-if="i.isFriendsTextOrImageText == 1"
          >
            <div class="sp" @click="ImageTextLogoViewFun(indexs)">
              {{
                i.imageText.state
                  ? '已配置图文链接'
                  : '请配置图文链接！！！'
              }}
            </div>
          </el-form-item>
          <el-form-item
            label="卡片："
            v-if="
              i.imageText.state &&
                automaticList.pyq &&
                i.isFriendsTextOrImageText == 1
            "
          >
            <div class="flex">
              <div>
                <img
                  class="img"
                  :src="i.imageText.imageTextLogo.image"
                  alt=""
                />
              </div>
              <div>
                <div>
                  文案：{{ i.imageText.imageTextLogo.text1 }}
                </div>
                <div>
                  按钮文案：{{
                    i.imageText.imageTextLogo.buttonText
                  }}
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="落地页和文字链:">
            <template #default="scope">
              <el-table
                :data="i.pageSpec"
                border
                style="width: 100%"
              >
                <el-table-column prop="accountId" label="账户id" width="100">
                </el-table-column>
                <el-table-column label="落地页类型" width="140">
                  <template #default="scope">
                    <div class="zhgyTab">
                      <div>
                        <el-radio-group
                          v-model="scope.row.pageType"
                          size="mini"
                        >
                          <el-radio-button
                            :label="'PAGE_TYPE_WECHAT_CANVAS'"
                            @click="deletePageIdFun(scope, scope.$index)"
                            :disabled="
                              i.isFriendsTextOrImageText == 1
                            "
                            >原生推广页</el-radio-button
                          >
                          <el-radio-button
                            :label="'PAGE_TYPE_WECHAT_MINI_PROGRAM'"
                            >微信小程序</el-radio-button
                          >
                          <el-radio-button
                            :label="'PAGE_TYPE_OFFICIAL'"
                            @click="deletePageIdFun(scope, scope.$index)"
                            >官方落地页</el-radio-button
                          >
                        </el-radio-group>
                      </div>
                      <div v-if="scope.row.zhuanHuaGy == 1">
                        <el-select
                          v-model="scope.row.id"
                          filterable
                          placeholder="请选择数据源"
                          @click="
                            selectDataBaseFun(scope.row.accountId, scope.$index)
                          "
                        >
                          <el-option
                            v-for="i in scope.row.dataBase"
                            :key="i.userActionSetId"
                            :label="i.name"
                            :value="i.userActionSetId"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="原生推广页" width="140">
                  <template #default="scope">
                    <el-select
                      :disabled="i.isFriendsTextOrImageText == 1"
                      v-if="scope.row.pageType == 'PAGE_TYPE_WECHAT_CANVAS'"
                      v-model="scope.row.pageId"
                      filterable
                      placeholder="请选择原生推广页"
                      @click="selectWechatPagesGetFun(scope,indexs)"
                    >
                      <el-option
                        v-for="i in scope.row.pageIdList"
                        :key="i.pageId"
                        :label="i.pageName"
                        :value="i.pageId"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="小程序原始ID" width="140">
                  <template #default="scope">
                    <el-input
                      v-if="
                        scope.row.pageType == 'PAGE_TYPE_WECHAT_MINI_PROGRAM'
                      "
                      placeholder="请输入小程序原始ID"
                      v-model="scope.row.miniProgramId"
                      clearable
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column label="小程序链接" width="140">
                  <template #default="scope">
                    <el-input
                      v-if="
                        scope.row.pageType == 'PAGE_TYPE_WECHAT_MINI_PROGRAM'
                      "
                      placeholder="请输入小程序链接"
                      v-model="scope.row.miniProgramPath"
                      clearable
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column label="官方落地页" width="140" >
                  <template #default="scope">
                    <el-select
                    v-if="scope.row.pageType == 'PAGE_TYPE_OFFICIAL'"
                      v-model="scope.row.pageId"
                      filterable
                      placeholder="请选择官方落地页"
                      @click="selectPagesGetFun(scope,indexs)"
                    >
                      <el-option
                        v-for="i in scope.row.officialPageIdList"
                        :key="i.pageId"
                        :label="i.pageName"
                        :value="i.pageId"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  label="朋友圈文字链"
                  width="120"
                  v-if="
                    automaticList.pyq == true &&
                      i.isFriendsTextOrImageText == 0
                  "
                >
                  <template #default="scope">
                    <el-switch
                      v-model="scope.row.mpLinkSpecDto.linkSpecFlag"
                      active-text="开启"
                      inactive-text=""
                    >
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  label="文字链文案"
                  width="160"
                  v-if="
                    automaticList.pyq == true &&
                      i.isFriendsTextOrImageText == 0
                  "
                >
                  <template #default="scope">
                    <el-select
                      :disabled="!scope.row.mpLinkSpecDto.linkSpecFlag"
                      v-model="scope.row.mpLinkSpecDto.linkNameType"
                      filterable
                      placeholder="文字链文案"
                      @click="selectPengyouQuanFun(scope.$index,indexs)"
                    >
                      <el-option
                        v-for="i in scope.row.mpLinkSpecDto
                          .selectPengyouQuanList"
                        :key="i.value"
                        :label="i.lable"
                        :value="i.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  label="跳转落地页"
                  v-if="
                    automaticList.pyq == true &&
                      i.isFriendsTextOrImageText == 0
                  "
                >
                  <template #default="scope">
                    <div>
                      <el-radio-group
                        :disabled="!scope.row.mpLinkSpecDto.linkSpecFlag"
                        v-model="scope.row.mpLinkSpecDto.linkPageType"
                      >
                        <el-radio-button
                          :label="'PAGE_TYPE_WECHAT_MINI_PROGRAM'"
                          >微信小程序</el-radio-button
                        >
                      </el-radio-group>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-form-item>
          <el-form-item label="创意名称">
            <el-input
              placeholder="请输入创意名称"
              v-model="i.adcreativeName"
              clearable
            >
            </el-input>
          </el-form-item>
        </el-form>
        <el-form-item>

        </el-form-item>
        
        <div class="addbtn flex" v-if="automaticList.dynamicCreativeType=='DELIVERY_MODE_COMPONENT'">
          <div>当前{{ mpAdcreativesDto.length }}条</div>
          <el-button type="danger" v-if="mpAdcreativesDto.length>1" @click="deleteData(indexs)">删除</el-button>
          <el-button type="primary" @click="addData(indexs)">新增一条创意</el-button>
        </div>

      </el-card>

    </div>
    <div>
          <el-button type="primary" @click="confirm()">确定</el-button>
        </div>
    <!-- 品牌标识控制 -->
    <el-dialog title="请选择品牌标识" v-model="brandLogoView">
      <div>
        <div
          v-for="item in mpAdcreativesDto[cardIndex].mpBrandDto.brandData"
          class="brand flex"
          @click="clikeBrandFun(item)"
        >
          <div><img class="img" :src="item.image" alt="" /></div>
          <div>{{ item.brandName }}</div>
        </div>
        <div class="ppbs_btn flex">
          <div>
            <span>找不到品牌标识？请</span
            ><span
              ><el-button type="text" @click="add_ppbsFun"
                >上传品牌标识</el-button
              ></span
            >
          </div>
          <div>
            <el-button type="text" @click="select_ppbsFun()">刷新</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 添加品牌标识弹窗控制 -->
    <el-dialog title="添加品牌标识" v-model="ppbs_Visibles">
      <div>
        <el-form
          :label-position="right"
          label-width="100px"
          :model="add_ppbsData"
          style="max-width: 460px"
        >
          <el-form-item label="品牌名称:">
            <el-input v-model="add_ppbsData.brandName" />
          </el-form-item>
          <el-form-item label="品牌头像:">
            <div>
              <!-- action="https://wxappvue.df01.top/api/brand/uploadBrand" -->
              <!--  action="http://dfrt.natapp4.cc/brand/uploadBrand" -->
              <el-upload
                class="upload-demo"
                :headers="{ authorize: token }"
                ref="upload"
                :data="{
                  brandName: add_ppbsData.brandName,
                }"
                action="https://wxappvue.df01.top/api/brand/uploadBrand"
                :file-list="add_ppbsData.fileList"
                :auto-upload="false"
                :on-success="successdata_ppbs_Fun"
                :before-upload="before_upload_ppbs"
              >
                <template #trigger>
                  <el-button size="small" type="primary"
                    >选取品牌标识</el-button
                  >
                </template>
                <el-button
                  style="margin-left: 10px;"
                  size="small"
                  type="success"
                  @click="submit_ppbs_Upload"
                  >上传品牌标识到服务器</el-button
                >
                <template #tip>
                  <div class="el-upload__tip"></div>
                </template>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 浮层卡片控制 -->
    <el-dialog title="请选择浮层卡片" v-model="FuCengCardLogoView">
      <div>
        <div
          v-for="item in mpAdcreativesDto[cardIndex].FuCengCard.FuCengCardData"
          class="brand flex"
          @click="clikeFuCengCardFun(item)"
        >
          <div><img class="img" :src="item.image" alt="" /></div>
          <div>{{ item.brandName }}</div>
        </div>
        <div class="ppbs_btn flex">
          <div>
            <span>找不到浮层卡片？请</span
            ><span
              ><el-button type="text" @click="add_fckpFun"
                >上传浮层卡片</el-button
              ></span
            >
          </div>
          <div>
            <el-button type="text" @click="select_fckpFun(cardIndex)">刷新</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 添加浮层卡片弹窗控制 -->
    <el-dialog title="添加浮层卡片" v-model="fckp_Visibles">
      <div>
        <el-form
          :label-position="right"
          label-width="120px"
          :model="add_fckpData"
          style="max-width: 460px"
        >
          <el-form-item label="卡片名称:">
            <el-input v-model="add_fckpData.brandName" />
          </el-form-item>
          <el-form-item label="文案（1-10字）:">
            <el-input v-model="add_fckpData.text1" />
          </el-form-item>
          <el-form-item label="文案（1-14字）:">
            <el-input v-model="add_fckpData.text2" />
          </el-form-item>
          <el-form-item label="按钮文案:">
            <el-select
              v-model="add_fckpData.buttonText"
              filterable
              placeholder="请选择按钮文案"
              @click="selectFuCengCardTextFun()"
            >
              <el-option
                v-for="i in add_fckpData.buttonTextList"
                :key="i.value"
                :label="i.lable"
                :value="i.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创意图片:">
            <div>
              <!-- action="https://wxappvue.df01.top/api/brand/uploadFucengCard" -->
              <!--  action="http://dfrt.natapp4.cc/brand/uploadBrand" -->
              <el-upload
                class="upload-demo"
                :headers="{ authorize: token }"
                ref="upload"
                :data="{
                  brandName: add_fckpData.brandName,
                  text1: add_fckpData.text1,
                  text2: add_fckpData.text2,
                  buttonText: add_fckpData.buttonText,
                }"
                action="https://wxappvue.df01.top/api/brand/uploadFucengCard"
                :file-list="add_fckpData.fileList"
                :auto-upload="false"
                :on-success="successdata_fckp_Fun"
                :before-upload="before_upload_fckp"
              >
                <template #trigger>
                  <el-button size="small" type="primary"
                    >选取浮层卡片</el-button
                  >
                </template>
                <el-button
                  style="margin-left: 10px;"
                  size="small"
                  type="success"
                  @click="submit_fckp_Upload"
                  >上传浮层卡片到服务器</el-button
                >
                <template #tip>
                  <div class="el-upload__tip"></div>
                </template>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 图文链接控制 -->
    <el-dialog title="请选择图文链接" v-model="ImageTextLogoView">
      <div>
        <div
          v-for="item in mpAdcreativesDto.imageText.imageTextData"
          class="brand flex"
          @click="clikeImageTextFun(item)"
        >
          <div><img class="img" :src="item.image" alt="" /></div>
          <div>
            <div>文案：{{ item.text1 }}</div>
            <div>按钮文案：{{ item.buttonText }}</div>
          </div>
        </div>
        <div class="ppbs_btn flex">
          <div>
            <span>找不到图文链接？请</span
            ><span
              ><el-button type="text" @click="add_twljFun"
                >上传图文链接</el-button
              ></span
            >
          </div>
          <div>
            <el-button type="text" @click="select_twljFun()">刷新</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 添加图文链接弹窗控制 -->
    <el-dialog title="添加图文链接" v-model="twlj_Visibles">
      <div>
        <el-form
          :label-position="right"
          label-width="120px"
          :model="add_twljData"
          style="max-width: 460px"
        >
          <el-form-item label="文案（1-8字）:">
            <el-input v-model="add_twljData.text1" />
          </el-form-item>
          <el-form-item label="按钮文案:">
            <el-select
              v-model="add_twljData.buttonText"
              filterable
              placeholder="请选择按钮文案"
            >
              <el-option
                v-for="i in add_twljData.buttonTextData"
                :key="i.value"
                :label="i.lable"
                :value="i.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创意图片:">
            <div>
              <!-- action="https://wxappvue.df01.top/api/brand/uploadImageText" -->
              <!--  action="http://dfrt.natapp4.cc/brand/uploadImageText" -->
              <!-- action="https://wxappvue.df01.top/api/brand/uploadImageText" -->
              <el-upload
                class="upload-demo"
                :headers="{ authorize: token }"
                ref="upload"
                :data="{
                  text1: add_twljData.text1,
                  buttonText: add_twljData.buttonText,
                }"
                action="https://wxappvue.df01.top/api/brand/uploadImageText"
                :file-list="add_twljData.fileList"
                :auto-upload="false"
                :on-success="successdata_twlj_Fun"
                :before-upload="before_upload_twlj"
              >
                <template #trigger>
                  <el-button size="small" type="primary"
                    >选取图文链接</el-button
                  >
                </template>
                <el-button
                  style="margin-left: 10px;"
                  size="small"
                  type="success"
                  @click="submit_twlj_Upload"
                  >上传图文链接到服务器</el-button
                >
                <template #tip>
                  <div class="el-upload__tip"></div>
                </template>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <div></div>
  </div>
</template>

<script>
import {
  selectFuCengCardText,
  selectPengyouQuan,
  selectBrand,
  selectbuttonText,
  selectPromotedObjectsGetV3,
  selectFuCengCard,
  selectWechatPagesGet,
  selectPagesGet,
  selectImageText,
} from '@/request/new/batchManagement'
import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs, watch } from 'vue'
import pagination from '@/components/pagination.vue' // 导入分页组件
import selectMPVideo from '@/components/batch/selectMPVideo.vue'
import ShowVideo from '@/components/batch/showVideo.vue'

export default {
  name: 'videoSucai',
  components: { pagination, selectMPVideo, ShowVideo },
  props: ['mpAdcreativesDto', 'automaticList'],
  setup(props, context) {
    const state = reactive({
      //识别是在哪里
      cardIndex:0,
      //创意数据
      mpAdcreativesDto: props.mpAdcreativesDto,
      // 所在区域
      area: 'on',
      // 获取用户token
      token: 'Bearer' + localStorage.getItem('token'),
      //是否抛出去
      AdcreativesType: true,
      //创意视频下标
      videoIndex: '',
      // 添加品牌标识的数据
      add_ppbsData: {
        // 品牌名称
        brandName: '',
        // 品牌头像
        headImage: '',
        // 图片数据
        fileList: [],
      },


      // 添加浮层卡片的数据
      add_fckpData: {
        // 卡片名称
        brandName: '',
        // 文案1
        text1: '',
        // 文案2
        text2: '',
        // 按钮文案
        buttonText: '',
        // 按钮文案集合
        buttonTextList: [],
        // 品牌头像
        headImage: '',
        // 图片数据
        fileList: [],
      },

      // 添加图文链接的数据
      add_twljData: {
        // 文案1
        text1: '',
        // 按钮文案
        buttonText: '',
        // 按钮文案数据
        buttonTextData: [
          { value: '全集观看', label: '全集观看' },
          { value: '观看', label: '观看' },
          { value: '了解更多', label: '了解更多' },
        ],
      },
      //新增一条
      addData:(indexs)=>{
        const data=JSON.parse(JSON.stringify(state.mpAdcreativesDto[indexs]))
        console.log("🚀 ~ setup ~ state.mpAdcreativesDto[indexs]:", state.mpAdcreativesDto[indexs])
        //消除文案
        // data.textDtos.forEach((e)=>{
        //   e.texts=[{text: ''}]
        // })
        console.log("🚀 ~ setup ~ data:", data)
        //消除视频
        data.videoDtos.vids=[]
        data.videoDtos.vidslist=[]
        data.videoDtos.selectTid=''
        state.mpAdcreativesDto.push(data)

      },
        
        //删除一条
        deleteData:(indexs)=>{
        state.mpAdcreativesDto.splice(indexs,1)
      },

      // 点击品牌
      clikeBrandFun: (val) => {
        state.mpAdcreativesDto[state.cardIndex].mpBrandDto.brandLogo = val
        state.mpAdcreativesDto[state.cardIndex].mpBrandDto.brandId = val.id
        state.mpAdcreativesDto[state.cardIndex].mpBrandDto.state = true
        // state.cypz_ruleForm.Visibles = false
        state.brandLogoView = false
      },
      //查询品牌按钮
      selectbuttonTextFun: (indexs) => {
        selectbuttonText().then((res) => {
          state.mpAdcreativesDto[indexs].mpBrandDto.buttonTextData = res.data
        })
      },

      // 点击浮沉卡片
      clikeFuCengCardFun: (val) => {
        console.log(
          '%c [ val ]-535',
          'font-size:13px; background:pink; color:#bf2c9f;',
          val
        )
        state.mpAdcreativesDto[state.cardIndex].FuCengCard.FuCengCardLogo = val
        state.mpAdcreativesDto[state.cardIndex].FuCengCard.FuCengCardId = val.id
        state.mpAdcreativesDto[state.cardIndex].fuCengCardId = val.id
        state.mpAdcreativesDto[state.cardIndex].FuCengCard.state = true
        // state.cypz_ruleForm.Visibles = false
        state.FuCengCardLogoView = false
      },

      // 点击图文链接
      clikeImageTextFun: (val) => {
        console.log(
          '%c [ val ]-535',
          'font-size:13px; background:pink; color:#bf2c9f;',
          val
        )
        state.mpAdcreativesDto[state.cardIndex].imageText.imageTextLogo = val
        state.mpAdcreativesDto[state.cardIndex].imageText.imageTextId = val.id
        state.mpAdcreativesDto[state.cardIndex].imageTextId = val.id
        state.mpAdcreativesDto[state.cardIndex].imageText.state = true

        state.ImageTextLogoView = false
      },
      //查询视频号
      selectPromotedObjectsGetV3Fun: (scope,indexs) => {
        console.log(
          '%c [ item,index ]-410',
          'font-size:13px; background:pink; color:#bf2c9f;',
          scope
        )
        const datas = {
          accountId: scope.row.accountId,
        }
        selectPromotedObjectsGetV3(datas).then((res) => {
          state.mpAdcreativesDto[indexs].promotedObjects[scope.$index].userNameData =
            res.data
          console.log(
            '%c [  ]-416',
            'font-size:13px; background:pink; color:#bf2c9f;',
            state.mpAdcreativesDto[indexs].promotedObjects[scope.$index].userNameData
          )
        })
      },

      //广告特定版位数据
      automaticList: props.automaticList,

      // 品牌标识弹窗
      brandLogoView: false,
      // 添加品牌标识弹窗控制
      ppbs_Visibles: false,
      // 浮层卡片标识弹窗
      FuCengCardLogoView: false,
      // 添加浮层卡片弹窗控制
      fckp_Visibles: false,

      // 图文链接标识弹窗
      ImageTextLogoView: false,
      // 添加图文链接弹窗控制
      twlj_Visibles: false,

      //文案数组

      // 新增标题(打开新页面)
      openNewWindows: () => {
        window.open('MPtitleGroup') // 打开标题组页面
      },
      //添加文案
      addWenanFun: (scope,indexs) => {
        console.log("🚀 ~ setup ~ scope,indexs:", scope,indexs)
        
        if (state.mpAdcreativesDto[indexs].textDtos[scope.$index].texts.length < 10) {
          state.mpAdcreativesDto[indexs].textDtos[scope.$index].texts.push({
            text: '',
          })
        }
      },
      //删除文案
      deleteWenanFun: (scope, index,indexs) => {
        if (state.mpAdcreativesDto[indexs].textDtos[scope.$index].texts.length > 1) {
          state.mpAdcreativesDto[indexs].textDtos[scope.$index].texts.splice(index, 1)
        }
      },
      //查询文字链文案
      selectPengyouQuanFun: (index,indexs) => {
        selectPengyouQuan().then((res) => {
          state.mpAdcreativesDto[indexs].pageSpec[
            index
          ].mpLinkSpecDto.selectPengyouQuanList = res.data
        })
      },

      //浮层卡片按钮文案（广告计划-MP）
      selectFuCengCardTextFun: () => {
        selectFuCengCardText().then((res) => {
          state.add_fckpData.buttonTextList = res.data
        })
      },

      //查询原生推广页
      selectWechatPagesGetFun: (scope,indexs) => {
        const datas = {
          accountId: scope.row.accountId,
        }
        selectWechatPagesGet(datas).then((res) => {
          state.mpAdcreativesDto[indexs].pageSpec[scope.$index].pageIdList = res.data
        })
      },
      //清空落地页id（由于原生推广页和官方落地页共用的关系）
      deletePageIdFun:(scope,indexs)=>{
        state.mpAdcreativesDto[indexs].pageSpec[scope.$index].pageId = ''
      },

        //查询官方落地页
        selectPagesGetFun: (scope,indexs) => {
        const datas = {
          accountId: scope.row.accountId,
        }
        selectPagesGet(datas).then((res) => {
          state.mpAdcreativesDto[indexs].pageSpec[scope.$index].officialPageIdList = res.data
        })
      },

      // 打开选择品牌标识
      brandLogoViewFun: (indexs) => {
        state.cardIndex=indexs
        state.brandLogoView = true
        selectBrand().then((res) => {
          state.mpAdcreativesDto[indexs].mpBrandDto.brandData = res.data
        })
      },
      // 刷新品牌标识
      select_ppbsFun: () => {
        selectBrand().then((res) => {
          state.mpAdcreativesDto[cardIndex].mpBrandDto.brandData = res.data
        })
      },

      // 刷新浮层卡片
      select_fckpFun: () => {
        selectFuCengCard().then((res) => {
          state.mpAdcreativesDto[cardIndex].FuCengCard.FuCengCardData = res.data
        })
      },

      // 刷新图文链接
      select_twljFun: () => {
        console.log(
          '%c [ 水电费 ]-1007',
          'font-size:13px; background:pink; color:#bf2c9f;',
          11
        )
        selectImageText().then((res) => {
          state.mpAdcreativesDto[cardIndex].imageText.imageTextData = res.data
        })
      },

      // 打开上传品牌标识弹窗
      add_ppbsFun: () => {
        state.ppbs_Visibles = true
      },

      // 打开上传浮层卡片弹窗
      add_fckpFun: () => {
        state.fckp_Visibles = true
      },

      // 打开选择浮层卡片
      FuCengCardLogoViewFun: (indexs) => {
        state.FuCengCardLogoView = true
        state.cardIndex=indexs
        selectFuCengCard().then((res) => {
          state.mpAdcreativesDto[indexs].FuCengCard.FuCengCardData = res.data
        })
      },
      // 打开上传图文链接弹窗
      add_twljFun: () => {
        state.twlj_Visibles = true
      },
      // 打开选择图文链接
      ImageTextLogoViewFun: (indexs) => {
        state.ImageTextLogoView = true
        state.cardIndex=indexs
        selectImageText().then((res) => {
          state.mpAdcreativesDto[indexs].imageText.imageTextData = res.data
        })
      },
      // 添加视频
      selectTitleFun: (indexs,index) => {
        //
        state.cardIndex=indexs
        // 选中视频所在数组index
        state.videoIndex = index
        console.log(
          '【外：添加视频==》】',
          state.videoIndex,
          '数据',
          state.mpAdcreativesDto[indexs].selectVideoData
        )

        console.log(
          '%c [ state.videoIndex ]-867',
          'font-size:13px; background:pink; color:#bf2c9f;',
          state.videoIndex
        )

        console.log(
          '%c [state.mpAdcreativesDto  ]-869',
          'font-size:13px; background:pink; color:#bf2c9f;',
          state.mpAdcreativesDto[indexs]
        )
        state.mpAdcreativesDto[indexs].videoDtos[index].wt = !state.mpAdcreativesDto[indexs]
          .videoDtos[index].wt
      },
      // 获取组件抛出选中的标题组id
      getTitleId: (val) => {
        state.mpAdcreativesDto[state.cardIndex].videoDtos[state.videoIndex].selectTid = val
        console.log('【外界】接收到了组件抛出选中的标题组id', val)
      },
      // 获取选中的数据组
      getSelectSucaiVideo: (val) => {
        state.mpAdcreativesDto[state.cardIndex].videoDtos[state.videoIndex].vidslist = val
        console.log('【外界】接收到了val', val)
        state.mpAdcreativesDto[state.cardIndex].videoDtos[state.videoIndex].wt = false
      },
      // 获取【展示组件】选中数据组的改变
      getsucaiDataChange: (index, index2) => {
        console.log(
          '【外界展示页面】allArr标题组视频改变',
          index,
          '==外面组件第几行索引=',
          index2
        )
        //移除要删除的视频   index2就是展示的索引
        state.mpAdcreativesDto[state.cardIndex].videoDtos[index2].vidslist.splice(index, 1)
      },
      //获取定位
      getIndexs:(indexs)=>{
        //获取定位删除视频,解决切换弹窗之后换不了视频的痛点
        state.cardIndex=indexs
      },

      // 确定
      confirm: () => {

        state.AdcreativesType = true
        
        state.mpAdcreativesDto.forEach((el)=>{
          // //校验创意视频
          el.videoDtos.forEach((e) => {
          if (e.vidslist.length == 0) {
            state.AdcreativesType = false
            return ElMessage({
              type: 'error',
              message: '创意视频不能为空',
            })
          }
        })


                //校验文案
          el.textDtos.forEach((e) => {
          e.texts.forEach((item, index) => {
            if (item.text == '') {
              state.AdcreativesType = false
              return ElMessage({
                type: 'error',
                message:
                  '账户id：' +
                  e.accountId +
                  '的第' +
                  (index + 1) +
                  '个文案不能为空',
              })
            }
            if (item.text.length > 31) {
              state.AdcreativesType = false
              return ElMessage({
                type: 'error',
                message:
                  '账户id：' +
                  e.accountId +
                  '的第' +
                  (index + 1) +
                  '个文案不能超过30字',
              })
            }
          })
        })


        //校验浮层卡片
        if (
          el.promotedObjects.userName == '' &&
          state.automaticList.sph
        ) {
          return ElMessage({
            type: 'error',
            message: '浮层卡片不能为空',
          })
        }

        //校验图文链接
        if (
          el.imageText.imageTextId == '' &&
          state.automaticList.pyq &&
          el.isFriendsTextOrImageText == 1
        ) {
          return ElMessage({
            type: 'error',
            message: '图文链接不能为空',
          })
        }

                //校验品牌
                if (
          el.mpBrandDto.brandId == '' &&
          !state.automaticList.sph
        ) {
          return ElMessage({
            type: 'error',
            message: '品牌不能为空',
          })
        }

                //校验品牌按钮
                if (el.mpBrandDto.buttonText == '') {
          return ElMessage({
            type: 'error',
            message: '品牌按钮不能为空',
          })
        }


        //校验品牌描述信息
        if (
          el.mpBrandDto.brandDesc == '' &&
          state.automaticList.pyq
        ) {
          return ElMessage({
            type: 'error',
            message: '品牌描述信息不能为空',
          })
        }
        //校验创意名称
        if (el.adcreativeName == '') {
          return ElMessage({
            type: 'error',
            message: '创意名称不能为空',
          })
        }
        //校验视频号
        el.promotedObjects.forEach((item) => {
          if (item.userName == '' && state.automaticList.sph) {
            state.AdcreativesType = false
            return ElMessage({
              type: 'error',
              message: '视频号不能为空',
            })
          }
        })


                //校验落地页和文字链
                el.pageSpec.forEach((item) => {
          if (
            item.miniProgramId == '' &&
            item.pageType == 'PAGE_TYPE_WECHAT_MINI_PROGRAM'
          ) {
            state.AdcreativesType = false
            return ElMessage({
              type: 'error',
              message: '小程序原始id不能为空',
            })
          }
          if (
            item.miniProgramPath == '' &&
            item.pageType == 'PAGE_TYPE_WECHAT_MINI_PROGRAM'
          ) {
            state.AdcreativesType = false
            return ElMessage({
              type: 'error',
              message: '小程序链接不能为空',
            })
          }
          if (item.pageId == '' && (item.pageType == 'PAGE_TYPE_WECHAT_CANVAS'||item.pageType == 'PAGE_TYPE_OFFICIAL')) {
            state.AdcreativesType = false
            return ElMessage({
              type: 'error',
              message: '《原生推广》或者《官方落地页》不能为空',
            })
          }

          if (
            item.mpLinkSpecDto.linkSpecFlag == true &&
            state.automaticList.pyq &&
            el.isFriendsTextOrImageText == 0
          ) {
            if (item.mpLinkSpecDto.linkNameType == '') {
              state.AdcreativesType = false
              return ElMessage({
                type: 'error',
                message: '文字链文案不能为空',
              })
                
            }
          }
        })

       
        })



        //给视频赋值
        state.mpAdcreativesDto.forEach((el)=>{
          el.videoDtos.forEach((e)=>{
            e.vids=[]
            e.vidslist.forEach((s)=>{
              e.vids.push(s.id)
            })
          })
        })



        
        if (state.AdcreativesType == true) {
          context.emit('getAdcreativesListFun', state.mpAdcreativesDto)
          // if(state.automaticList.dynamicCreativeType=='DELIVERY_MODE_COMPONENT'){
          //   context.emit('getAdcreativesListFun', state.mpAdcreativesDto)
          // }else{
          //   context.emit('getAdcreativesListFun', state.mpAdcreativesDto[0])
          // }
           
           
          
          // //先注释掉 链接选择
          // state.mpAdcreativesDto.isFriendsTextOrImageText = ''

        }

        console.log(
          '%c [创意]-528',
          'font-size:13px; background:pink; color:#bf2c9f;',
          state.mpAdcreativesDto
        )
      },
    })
    //自动添加一行文案
    const autoAddOneWenanFun = () => {
      state.mpAdcreativesDto[0].textDtos.forEach((e, index) => {
        if (e.texts.length == 0) {
          state.mpAdcreativesDto[0].textDtos[index].texts.push({
            text: '',
          })
        }
      })
    }

    autoAddOneWenanFun()
    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
      autoAddOneWenanFun,
    }
  },
  methods: {
    // 添加品牌标识
    submit_ppbs_Upload() {
      if (this.add_ppbsData.brandName == '') {
        ElMessage({
          type: 'error',
          message: '品牌名称不能为空',
        })
        return
      }
      this.$refs.upload.submit()
    },
    // 添加品牌标识成功
    successdata_ppbs_Fun(response, file, fileList) {
      if (response.code == 500) {
        ElMessage({
          type: 'error',
          message: response.msg,
        })
      } else {
        ElMessage({
          type: 'success',
          message: '品牌标识上传成功',
        })
        this.ppbs_Visibles = false
        this.select_ppbsFun()
      }
      this.add_ppbsData.brandName = ''
      this.add_ppbsData.fileList = []
    },
    // 添加品牌标识前校验
    before_upload_ppbs(file) {
      console.log(file)
      const isIMG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif'
      const isLt2M = file.size / 1024 < 400

      if (!isIMG) {
        this.$message.error('上传图片只能是 JPG、PNG、GIF 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 400kb!')
      }
      return isIMG && isLt2M
    },

    //添加浮层卡片
    submit_fckp_Upload() {
      if (
        this.add_fckpData.brandName == '' ||
        this.add_fckpData.text1 == '' ||
        this.add_fckpData.text2 == '' ||
        this.add_fckpData.buttonText == ''
      ) {
        ElMessage({
          type: 'error',
          message: '数据不能为空',
        })
        return
      }
      this.$refs.upload.submit()
    },
    // 添加浮层卡片成功
    successdata_fckp_Fun(response, file, fileList) {
      if (response.code == 500) {
        ElMessage({
          type: 'error',
          message: response.msg,
        })
      } else {
        ElMessage({
          type: 'success',
          message: '浮层卡片上传成功',
        })
        this.fckp_Visibles = false
        this.select_fckpFun(cardIndex)
      }
      this.add_fckpData.brandName = ''
      this.add_fckpData.text1 = ''
      this.add_fckpData.text2 = ''
      this.add_fckpData.buttonText = ''
      this.add_fckpData.fileList = []
    },
    // 添加浮层卡片前校验
    before_upload_fckp(file) {
      console.log(file)
      const isIMG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif'
      const isLt2M = file.size / 1024 < 400

      if (!isIMG) {
        this.$message.error('上传图片只能是 JPG、PNG、GIF 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 400kb!')
      }
      return isIMG && isLt2M
    },

    //添加图文链接
    submit_twlj_Upload() {
      if (this.add_twljData.text1 == '' || this.add_twljData.buttonText == '') {
        ElMessage({
          type: 'error',
          message: '数据不能为空',
        })
        return
      }
      if (this.add_twljData.text1.length > 8) {
        ElMessage({
          type: 'error',
          message: '文案长度不能超过8',
        })
        return
      }
      this.$refs.upload.submit()
    },
    // 添加图文链接成功
    successdata_twlj_Fun(response, file, fileList) {
      if (response.code == 500) {
        ElMessage({
          type: 'error',
          message: response.msg,
        })
      } else {
        ElMessage({
          type: 'success',
          message: '图文链接上传成功',
        })
        this.twlj_Visibles = false
        this.select_twljFun()
      }
      this.add_twljData.text1 = ''
      this.add_twljData.buttonText = ''
      this.add_twljData.fileList = []
    },
    // 添加图文链接前校验
    before_upload_twlj(file) {
      console.log(file)
      const isIMG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif'
      const isLt2M = file.size / 1024 < 400

      if (!isIMG) {
        this.$message.error('上传图片只能是 JPG、PNG、GIF 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 400kb!')
      }
      return isIMG && isLt2M
    },
  },
}
</script>

<style lang="scss" scoped>
.ldTitle {
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
  color: #409eff;
}

.sp {
  border: 1px solid #000;
  cursor: pointer;
  color: rgb(255, 0, 0);
}

.img {
  width: 50px;
  height: 50px;
}

.brand {
  background: rgb(243, 196, 142);
  border-bottom: 2px solid rgb(247, 248, 248);
  cursor: pointer;
}

.ppbs_btn {
  justify-content: space-between;
}

.wenanDIV {
  margin-top: 10px;
}

.el-input {
  width: 500px;
}

.addbtn{
  justify-content:flex-end
}
</style>
