<template>
  <div class="paddbox autobox">
    <div>
      <el-card>
        <el-form label-position="right" label-width="100px">
          <el-form-item label="营销目的">
            <el-radio-group v-model="dataList.marketingGoal">
              <el-radio-button :label="'MARKETING_GOAL_PRODUCT_SALES'"
                >商品营销</el-radio-button
              >
              <el-radio-button :label="'MARKETING_GOAL_USER_GROWTH'"
                >用户增长</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="推广产品">
            <el-radio-group v-model="dataList.marketingTargetType">
              <el-radio-button :label="'MARKETING_TARGET_TYPE_SHORT_DRAMA'">短剧</el-radio-button>
              <el-radio-button :label="'MARKETING_TARGET_TYPE_MINI_PROGRAM_WECHAT'">微信小程序</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="小程序原始ID" v-if="dataList.marketingTargetType == 'MARKETING_TARGET_TYPE_MINI_PROGRAM_WECHAT'">
            <el-input
              style="width:200px"
              placeholder="小程序原始 ID"
              v-model="dataList.marketingAssetOuterId"
              clearable
            >
            </el-input>
          </el-form-item>

          <el-form-item v-if="dataList.marketingTargetType == 'MARKETING_TARGET_TYPE_SHORT_DRAMA'">
            <el-table
              :data="dataList.marketingTargetAssets"
              border
              style="width: 100%"
            >
              <el-table-column prop="accountId" label="账户id" width="120">
              </el-table-column>
              <el-table-column label="搜索产品" width="200">
                <template #default="scope">
                  <el-form-item>
                    <el-input
                      size="mini"
                      style="width:120px"
                      placeholder="请输入产品名称"
                      v-model="scope.row.name"
                      clearable
                    >
                    </el-input>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="marketingTargetAssetsGetFun(scope)"
                      >搜索</el-button
                    >
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="accountId" label="产品">
                <template #default="scope">
                  <el-select
                    v-model="scope.row.marketingAssetId"
                    clearable
                    filterable
                    placeholder="请选择产品"
                  >
                    <el-option
                      v-for="item in scope.row.marketingAssetData"
                      :key="item.marketingAssetId"
                      :label="item.marketingAssetName"
                      :value="item.marketingAssetId"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>

          <!-- 短剧售卖策略-->
          <el-form-item label="短剧售卖策略" v-if="dataList.marketingGoal=='MARKETING_GOAL_PRODUCT_SALES'
">
            <el-table :data="dataList.strategyDtos" border style="width: 100%">
              <el-table-column prop="accountId" label="账户id" width="120">
              </el-table-column>
              <el-table-column label="售卖方式">
                <template #default="scope">
                  <div class="zhgyTab">
                    <div>
                      <el-radio-group v-model="scope.row.shortPlayPayType">
                        <el-radio-button
                          :label="'SHORT_PLAY_PAY_TYPE_FREE_PLAY'"
                          >免费</el-radio-button
                        >
                        <el-radio-button
                          :label="'SHORT_PLAY_PAY_TYPE_CHARGE_PLAY'"
                          >付费</el-radio-button
                        >
                      </el-radio-group>
                    </div>
                    <div
                      v-if="
                        scope.row.shortPlayPayType ==
                          'SHORT_PLAY_PAY_TYPE_CHARGE_PLAY'
                      "
                    >
                      <el-select
                        v-model="scope.row.sellStrategyId"
                        filterable
                        placeholder="请选择售卖策略"
                        @click="
                          selectLandingPageSellStrategyGetFun(
                            scope.row.accountId,
                            scope.$index
                          )
                        "
                      >
                        <el-option
                          v-for="i in scope.row.PayTypeBase"
                          :key="i.strategyId"
                          :label="i.strategyName"
                          :value="i.strategyId"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <div>
                  <el-button type="success" @click="creadStrategyGetFun()"
                    >多账号批量新建策略</el-button
                  >
                </div>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="营销载体类型">
            <el-radio-group v-model="dataList.marketingCarrierType">
              <el-radio-button :label="'MARKETING_CARRIER_TYPE_JUMP_PAGE'"
                >页面跳转</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="优化目标">
            <el-select
              @change="setSDYHMBFun()"
              v-model="dataList.optimizationGoal"
              filterable
              placeholder="请选择优化目标"
            >
              <el-option
                v-for="i in OPTIMIZATIONGOAL"
                :key="i.value"
                :label="i.lable"
                :value="i.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="深度优化目标"
            v-if="
              (dataList.checkVideo &&dataList.optimizationGoal == 'OPTIMIZATIONGOAL_CANVAS_CLICK')
                ||dataList.marketingGoal=='MARKETING_GOAL_USER_GROWTH'
                ||(dataList.marketingGoal=='MARKETING_GOAL_PRODUCT_SALES'&&dataList.optimizationGoal=='OPTIMIZATIONGOAL_APP_REGISTER')
            "
          >
            <el-select
              v-model="dataList.deepConversionBehaviorGoal"
              filterable
              clearable
              placeholder="请选择深度优化目标"
            >
              <el-option
                v-for="i in OPTIMIZATIONGOALdepth"
                :key="i.value"
                :label="i.lable"
                :value="i.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="广告版位">
            <div class="zhgyTab">
              <div>
                <el-radio-group v-model="dataList.automaticSiteEnabled">
                  <el-radio-button :label="true">自动版位</el-radio-button>
                  <el-radio-button :label="false">选择特定版位</el-radio-button>
                </el-radio-group>
              </div>
              <div v-if="dataList.automaticSiteEnabled == false">
                <el-checkbox-group v-model="dataList.siteSet">
                  <el-checkbox
                    :label="'SITE_SET_CHANNELS'"
                    @click="checkVideoFun()"
                    >微信视频号</el-checkbox
                  >
                  <el-checkbox :label="'SITE_SET_MOMENTS'"
                    >微信朋友圈</el-checkbox
                  >
                  <el-checkbox :label="'SITE_SET_WECHAT'"
                    >微信公众号与小程序</el-checkbox
                  >
                  <el-checkbox :label="'SITE_SET_WECHAT_PLUGIN'"
                    >微信新闻插件</el-checkbox
                  >
                  <el-checkbox :label="'SITE_SET_KANDIAN'"
                    >QQ 浏览器</el-checkbox
                  >
                  <el-checkbox :label="'SITE_SET_QQ_MUSIC_GAME'"
                    >QQ、腾讯音乐及游戏</el-checkbox
                  >
                  <el-checkbox :label="'SITE_SET_TENCENT_NEWS'"
                    >腾讯新闻</el-checkbox
                  >
                  <el-checkbox :label="'SITE_SET_TENCENT_VIDEO'"
                    >腾讯视频</el-checkbox
                  >
                  <el-checkbox :label="'SITE_SET_MOBILE_UNION'"
                    >优量汇</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="定向包">
            <el-table
              :data="dataList.targetingPackages"
              border
              style="width: 100%"
            >
              <el-table-column prop="accountId" label="账户id" width="120">
              </el-table-column>
              <el-table-column prop="accountId" label="定向包">
                <template #default="scope">
                  <el-select
                    v-model="scope.row.id"
                    filterable
                    placeholder="请选择定向包"
                    @click="selectDirectionalFun(scope)"
                  >
                    <el-option
                      @click="testProjectFun(scope)"
                      v-for="i in scope.row.Packages"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="出价方式">
            <el-radio-group v-model="dataList.bidMode">
              <el-radio-button :label="'BID_MODE_OCPM'">oCPM</el-radio-button>
              <el-radio-button :label="'BID_MODE_CPM'" disabled
                >CPM</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="出价类型">
            <el-radio-group v-model="dataList.smartBidType">
              <el-radio-button :label="'SMART_BID_TYPE_CUSTOM'"
                >稳定拿量</el-radio-button
              >
              <el-radio-button :label="'SMART_BID_TYPE_SYSTEMATIC'"
                >最大转化量转化</el-radio-button
              >
              <!-- <el-radio-button :label="'BID_STRATEGY_PRIORITY_CAP_COST'"
                >控制成本上限</el-radio-button
              > -->
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="出价"
            v-if="dataList.smartBidType != 'SMART_BID_TYPE_SYSTEMATIC'"
          >
            <el-input
              type="number"
              style="width:200px"
              placeholder="请输入出价"
              v-model="dataList.bidAmount"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="深度出价"
            v-if="
              dataList.checkVideo && dataList.deepConversionBehaviorGoal != ''&&dataList.marketingGoal=='MARKETING_GOAL_PRODUCT_SALES'
            "
          >
            <el-input
              type="number"
              style="width:200px"
              placeholder="请输入深度出价"
              v-model="dataList.deepConversionBehaviorBid"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="期望ROI"
            v-if="
              dataList.marketingGoal=='MARKETING_GOAL_USER_GROWTH'||(dataList.marketingGoal=='MARKETING_GOAL_PRODUCT_SALES'&&dataList.optimizationGoal=='OPTIMIZATIONGOAL_APP_REGISTER'&&dataList.deepConversionBehaviorGoal=='GOAL_1DAY_PURCHASE_ROAS')
            "
          >
            <el-input
              type="number"
              style="width:200px"
              placeholder="请输入期望ROI"
              v-model="dataList.expectedRoi"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="一键起量"
            v-if="dataList.smartBidType != 'SMART_BID_TYPE_SYSTEMATIC'"
          >
            <div class="zhgyTab">
              <div>
                <el-switch
                  v-model="dataList.autoAcquisitionEnabled"
                  active-text="开启"
                  inactive-text=""
                >
                </el-switch>
              </div>
              <div
                v-if="
                  dataList.autoAcquisitionEnabled == true &&
                    dataList.smartBidType != 'SMART_BID_TYPE_SYSTEMATIC'
                "
              >
                <el-input
                  type="number"
                  placeholder="请输入起量预算"
                  v-model="dataList.autoAcquisitionBudget"
                  clearable
                >
                </el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="广告日预算">
            <div class="zhgyTab">
              <div>
                <el-radio-group
                  v-model="dataList.dailyBudgetType"
                  v-if="dataList.smartBidType != 'SMART_BID_TYPE_SYSTEMATIC'"
                >
                  <el-radio-button :label="0" @click="cancelDailyBudget()">不限</el-radio-button>
                  <el-radio-button :label="1">指定日预算</el-radio-button>
                </el-radio-group>
              </div>
              <div
                v-if="
                  dataList.dailyBudgetType == 1 ||
                    dataList.smartBidType == 'SMART_BID_TYPE_SYSTEMATIC'
                "
              >
                <el-input
                  type="number"
                  placeholder="请输入广告日预算"
                  v-model="dataList.dailyBudget"
                  clearable
                >
                </el-input>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="投放日期">
            <div>
              <el-date-picker
                v-model="dataList.timeDate"
                type="daterange"
                :default-value="new Date()"
                value-format="YYYY-MM-DD"
                format="YYYY-MM-DD"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
            <div>
              <el-checkbox
                v-model="dataList.timeChecked"
                @change="timeCheckedFun()"
                >指定首日开始时间</el-checkbox
              >
            </div>
            <div v-if="dataList.timeChecked == true">
              <el-time-picker
                v-model="dataList.firstDayBeginTime"
                value-format="HH:mm:ss"
                placeholder="开始时间点"
              >
              </el-time-picker>
            </div>
          </el-form-item>
          <el-form-item label="投放时间">
            <div class="zhgyTab">
              <div>
                <el-radio-group v-model="dataList.timeFalg">
                  <el-radio-button :label="0">全天</el-radio-button>
                  <el-radio-button :label="1">指定多个时段</el-radio-button>
                </el-radio-group>
              </div>
              <div v-if="dataList.timeFalg == 1">
                <weektime
                  v-model="mult_timeRange"
                  :data="weektimeData"
                  @on-clear="clearWeektime"
                ></weektime>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="创意类型:">
      <el-radio-group v-model="dataList.dynamicCreativeType" size="large">
      <el-radio-button :label="'DELIVERY_MODE_CUSTOMIZE'"  >自定义创意</el-radio-button>
      <el-radio-button :label="'DELIVERY_MODE_COMPONENT'" >组件化创意</el-radio-button>
    </el-radio-group>
          </el-form-item>

          <!-- =========88888888888888888888888888888888888888888888888888888===== -->
        </el-form>

        <div>
          <el-form label-position="right" label-width="100px">
            <el-form-item>
              <el-radio-group v-model="dataList.isNewVersion">
                <el-radio-button :label="0">旧版</el-radio-button>
                <el-radio-button :label="1">新版</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <!-- 数据源 -->
            <el-form-item v-if="dataList.isNewVersion == 0">
              <el-table
                :data="dataList.userActionSets"
                border
                style="width: 100%"
              >
                <el-table-column prop="accountId" label="账户id" width="120">
                </el-table-column>
                <el-table-column label="转化归因">
                  <template #default="scope">
                    <div class="zhgyTab">
                      <div>
                        <el-radio-group v-model="scope.row.zhuanHuaGy">
                          <el-radio-button :label="0">全网归因</el-radio-button>
                          <el-radio-button :label="1"
                            >精准匹配归因</el-radio-button
                          >
                        </el-radio-group>
                      </div>
                      <div v-if="scope.row.zhuanHuaGy == 1">
                        <el-select
                          v-model="scope.row.id"
                          filterable
                          placeholder="请选择数据源"
                          @click="
                            selectDataBaseFun(scope.row.accountId, scope.$index)
                          "
                        >
                          <el-option
                            v-for="i in scope.row.dataBase"
                            :key="i.userActionSetId"
                            :label="i.userActionSetId"
                            :value="i.userActionSetId"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <!-- 转化id -->
            <el-form-item v-if="dataList.isNewVersion == 1">
              <el-table
                :data="dataList.mpConversions"
                border
                style="width: 100%"
              >
                <el-table-column prop="accountId" label="账户id" width="120">
                </el-table-column>
                <el-table-column label="转化">
                  <template #default="scope">
                    <div class="zhgyTab">
                      <div>
                        <el-select
                          v-model="scope.row.conversionId"
                          filterable
                          placeholder="请选择转化id"
                          @click="
                            conversionsGetV3Fun(
                              scope.row.accountId,
                              scope.$index
                            )
                          "
                        >
                          <el-option
                            v-for="i in scope.row.dataBase"
                            :key="i.conversionId"
                            :label="i.conversionName"
                            :value="i.conversionId"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <el-form-item label="广告名称">
              <el-input
                placeholder="请输入广告名称"
                v-model="dataList.adgroupName"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="confirm()">确定</el-button>
        </div>
      </el-card>
    </div>
  </div>

  <el-dialog v-model="strategyValue" title="新增售卖策略">
    <div>
      <div>
        账户：
      </div>
      <div>
        <el-checkbox-group v-model="checkedStrategysList">
          <el-checkbox v-for="i in accountIds" :label="i" />
        </el-checkbox-group>
      </div>
      <el-divider></el-divider>
      <div>
        <el-form
          class="demo-form-inline"
          :label-position="right"
          label-width="100px"
        >
          <el-form-item label="策略名称">
            <el-input
              v-model="strategyDto.strategyName"
              placeholder=""
              clearable
            />
          </el-form-item>
          <el-form-item label="单集价格">
            <el-input
              type="number"
              v-model="strategyDto.episodePrice"
              placeholder="精确到0.01，单位元"
              clearable
            />
          </el-form-item>
          <el-form-item label="最低充值档位">
            <el-input
              type="number"
              v-model="strategyDto.minRechargeTier"
              placeholder="精确到0.01，单位元"
              clearable
            />
          </el-form-item>
          <el-form-item label="起充集数">
            <el-input
              type="number"
              v-model="strategyDto.rechargeNum"
              placeholder=""
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="creadLandingPageSellStrategyGetFun"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import weektime from '@/components/weektime/weektime.vue'
import weektimeData from '@/components/weektime/weektime_data.js'
import {
  creadLandingPageSellStrategyGet,
  selectLandingPageSellStrategyGet,
  selectUserActionSetsGet,
  conversionsGetV3,
  userActionSetsget,
  marketingTargetAssetsGet,
  testProject,
} from '@/request/new/batchManagement'
import { selectDirectional } from '@/request/new/targetAdd'
import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs, watch, computed, ref } from 'vue'
import pagination from '@/components/pagination.vue' // 导入分页组件

export default {
  name: 'mpSetUpadvertisement',
  components: { pagination, weektime },
  props: ['tencentAdgroups', 'accountIdss'],
  setup(props, context) {
    const mult_timeRange = computed(() => {
      console.log('weektimeData', weektimeData)
      return weektimeData.map((item) => {
        return {
          id: item.row,
          week: item.value,
          value: splicing(item.child),
        }
      })
    })
    const state = reactive({
      clearWeektime: () => {
        state.weektimeData.forEach((item) => {
          item.child.forEach((t) => {
            t.check = false
          })
        })
      },
      //选中的子账户数据（外面已赋值）
      dataList: props.tencentAdgroups,
      //账户集合数组
      accountIds: props.accountIdss,
      //多个时段数据
      weektimeData: weektimeData,

      //优化目标的数据
      OPTIMIZATIONGOAL: [
        { lable: '下单', value: 'OPTIMIZATIONGOAL_ECOMMERCE_ORDER' },
        { lable: '跳转按钮点击', value: 'OPTIMIZATIONGOAL_CANVAS_CLICK' },
        { lable: '关注', value: 'OPTIMIZATIONGOAL_FOLLOW' },
        { lable: '注册', value: 'OPTIMIZATIONGOAL_APP_REGISTER' },
        { lable: '首日首次付费', value: 'OPTIMIZATIONGOAL_24H_FIRSTPAY' },
      ],
      //深度优化目标的数据
      OPTIMIZATIONGOALdepth: [
        { lable: '下单', value: 'OPTIMIZATIONGOAL_ECOMMERCE_ORDER' },
        { lable: '首日变现 ROI', value: 'GOAL_1DAY_MONETIZATION_ROAS' },
        { lable: '首日付费 ROI', value: 'GOAL_1DAY_PURCHASE_ROAS' },
      ],

      //多选的值=============
      checkAll: ref(false),
      isIndeterminate: ref(true),
      //选中的id和策略熟悉
      checkedStrategys: ref([]),

      //选中的id
      checkedStrategysList: props.accountIdss,
      //策略对象
      strategyDto: {
        //策略名称
        strategyName: '',
        //单集价格,精确到0.01，单位元
        episodePrice: '',
        //最低充值档位,精确到0.01，单位元
        minRechargeTier: '',
        //起充集数
        rechargeNum: '',
      },
      //是否要派出去
      confirmType: true,
      //策略弹窗控制
      strategyValue: false,

      //================新==================
      marketingTargetAssetsGetFun: (scope) => {
        const datas = {
          accountId: scope.row.accountId,
          name: scope.row.name,
        }
        marketingTargetAssetsGet(datas).then((res) => {
          state.dataList.marketingTargetAssets[
            scope.$index
          ].marketingAssetData = res.data
        })
      },
      //查询定向包
      selectDirectionalFun: (scope) => {
        const data = {
          currentPage: 1,
          size: 1000,
        }
        selectDirectional(data).then((res) => {
          state.dataList.targetingPackages[scope.$index].Packages =
            res.data.data
        })
      },
      //查询定向包是否可用
      testProjectFun: (scope) => {
        const data = {
          id: scope.row.id,
          accountId: scope.row.accountId,
        }
        testProject(data).then((res) => {})
      },
      //禁用之前的日期
      pickerOptions: {
        disabledDate(time) {
          // console.log(
          //   '%c [ disabledDate ]-582',
          //   'font-size:13px; background:pink; color:#bf2c9f;',
          //   time.getTime(),
          //   Date.now(),
          //   time.getTime() < Date.now()
          // )
          return time.getTime() < Date.now() - 8.64e7
        },
      },
      //效验是否开启视频号
      checkVideoFun: () => {
        if (
          state.dataList.siteSet.some((ele) => ele == 'SITE_SET_CHANNELS') ==
          false
        ) {
          state.dataList.checkVideo = true
        } else {
          state.dataList.checkVideo = false
        }

        // state.dataList.checkVideo
      },
      //日预算选了不限就清空日预算的数值
      cancelDailyBudget: () => {
        // console.log('日预算选了不限就清空日预算的数值',state.dataList.dailyBudgetType==0)
        // if(state.dataList.dailyBudgetType==0){
          state.dataList.dailyBudget=''
        // }
        
      },

      // =================新 end ========================
      handleCheckAllChange: (val) => {
        state.checkedStrategys.value = val ? state.accountIds : []
        state.isIndeterminate = false
      },

      handleCheckedCitiesChange: (value) => {
        const checkedCount = value.length
        checkAll.value = checkedCount === accountIds.length
        isIndeterminate.value =
          checkedCount > 0 && checkedCount < accountIds.length
      },
      /////==============================================

      //查询转化id
      //查询数据源
      selectDataBaseFun: (id, index) => {
        const datas = {
          accountId: id,
        }
        userActionSetsget(datas).then((res) => {
          state.dataList.userActionSets[index].dataBase = res.data
        })
      },
      //查询策略
      selectLandingPageSellStrategyGetFun: (id, index) => {
        const datas = {
          accountId: id,
        }
        selectLandingPageSellStrategyGet(datas).then((res) => {
          state.dataList.strategyDtos[index].PayTypeBase = res.data
        })
      },
      //创建策略
      creadLandingPageSellStrategyGetFun: () => {
        const datas = {
          accountIds: state.checkedStrategysList,
          strategyDto: state.strategyDto,
        }
        creadLandingPageSellStrategyGet(datas).then((res) => {
          state.strategyDto.strategyName = ''
          state.strategyDto.episodePrice = ''
          state.strategyDto.minRechargeTier = ''
          state.strategyDto.rechargeNum = ''
          state.creadStrategyGetFun()
        })
      },
      //查询转化id
      conversionsGetV3Fun: (id, index) => {
        const datas = {
          accountId: id,
        }
        conversionsGetV3(datas).then((res) => {
          state.dataList.mpConversions[index].dataBase = res.data
        })
      },
      //设置深度优化目标的值
      setSDYHMBFun:()=>{
        //当 营销目的=》商品营销 ，优化目标=》注册 则深度优化目标自动选定为首日付费 ROI
        if(state.dataList.marketingGoal=='MARKETING_GOAL_PRODUCT_SALES'&&state.dataList.optimizationGoal=='OPTIMIZATIONGOAL_APP_REGISTER'){
          state.dataList.deepConversionBehaviorGoal='GOAL_1DAY_PURCHASE_ROAS'
        }
        //当 营销目的=> 用户增长 ，优化目标=》注册 则深度优化目标自动选定为首日变现 ROI
        if(state.dataList.marketingGoal=='MARKETING_GOAL_USER_GROWTH'&&state.dataList.optimizationGoal=='OPTIMIZATIONGOAL_APP_REGISTER'){
          state.dataList.deepConversionBehaviorGoal='GOAL_1DAY_MONETIZATION_ROAS'
        }
      },
      //新建策略弹窗值的变化
      creadStrategyGetFun: () => {
        state.strategyValue = !state.strategyValue
      },

      //指定首日开始时间选中发送变化
      timeCheckedFun: () => {
        if (state.dataList.timeChecked == false) {
          state.dataList.firstDayBeginTime = ''
        }
      },
      //效验
      validateNumber: () => {
        // 使用正则表达式检查输入值是否为有效的数字（包括两位小数）
        if (!/^\d+(\.\d{1,2})?$/.test(value)) {
          // 如果不是有效的数字，您可以进行一些处理，例如清除输入或显示错误消息
          this.yourModel = '' // 清空输入框
          // 或者显示错误消息
          this.$message.error('请输入有效的数字，最多两位小数')
        }
      },
      //确定
      confirm: () => {
        state.confirmType = true
        //效验定向包
        state.dataList.targetingPackages.forEach((e) => {
          if (e.id == '') {
            return ElMessage({
              type: 'error',
              message: '定向包不能为空',
            })
          }
        })
        //效验用户增长
        if(state.dataList.marketingGoal=='MARKETING_GOAL_USER_GROWTH'&&state.dataList.smartBidType=='SMART_BID_TYPE_SYSTEMATIC'){
          return ElMessage({
              type: 'error',
              message: '用户增长不能选择最大转化量转化',
            })
        }


        //效验指定首日开始时间
        if (
          state.dataList.timeChecked == true &&
          state.dataList.firstDayBeginTime == ''
        ) {
          return ElMessage({
            type: 'error',
            message: '指定首日开始时间不能为空',
          })
        }
        //效验一键起量
        if (
          state.dataList.smartBidType != 'SMART_BID_TYPE_SYSTEMATIC' &&
          state.dataList.autoAcquisitionEnabled == true &&
          (state.dataList.autoAcquisitionBudget == '' ||
            state.dataList.autoAcquisitionBudget < 200)
        ) {
          return ElMessage({
            type: 'error',
            message: '一键起量起量预算设置错误',
          })
        }
        //效验广告日预算
        if (
          state.dataList.smartBidType == 'SMART_BID_TYPE_CUSTOM' &&
          state.dataList.dailyBudgetType == 1 &&
          state.dataList.dailyBudget == ''
        ) {
          return ElMessage({
            type: 'error',
            message: '广告日预算不能为空',
          })
        }
        //效验小程序原始 ID
        if (
          state.dataList.marketingTargetType == 'MARKETING_TARGET_TYPE_MINI_PROGRAM_WECHAT' &&
          state.dataList.marketingAssetOuterId == ''
        ) {
          return ElMessage({
            type: 'error',
            message: '小程序原始 ID不能为空',
          })
        }

        //效验出价
        if (
          state.dataList.smartBidType != 'SMART_BID_TYPE_SYSTEMATIC' &&
          state.dataList.bidAmount == ''
        ) {
          return ElMessage({
            type: 'error',
            message: '出价不能为空',
          })
        }
        //效验深度出价
        if (
          state.dataList.checkVideo == true &&
          state.dataList.deepConversionBehaviorGoal != '' &&
          state.dataList.marketingGoal == 'MARKETING_GOAL_PRODUCT_SALES' &&
          state.dataList.deepConversionBehaviorBid == ''
        ) {
          return ElMessage({
            type: 'error',
            message: '深度出价不能为空',
          })
        }
        //效验期望ROI
        if(((state.dataList.marketingGoal=='MARKETING_GOAL_USER_GROWTH'&&
        state.dataList.OPTIMIZATIONGOALdepth=='GOAL_1DAY_MONETIZATION_ROAS')||(state.dataList.OPTIMIZATIONGOALdepth=='GOAL_1DAY_PURCHASE_ROAS'&&state.dataList.marketingGoal=='MARKETING_GOAL_PRODUCT_SALES'&&state.dataList.optimizationGoal=='OPTIMIZATIONGOAL_APP_REGISTER'))&&
           state.dataList.expectedRoi==''){
            return ElMessage({
            type: 'error',
            message: '期望ROI不能为空',
          })
        }

        //效验广告名称
        if (state.dataList.adgroupName == '') {
          return ElMessage({
            type: 'error',
            message: '广告名称不能为空',
          })
        }
        //效验优化目标
        if (state.dataList.optimizationGoal == '') {
          return ElMessage({
            type: 'error',
            message: '优化目标不能为空',
          })
        }
        //效验广告版位
        if (
          state.dataList.automaticSiteEnabled == false &&
          state.dataList.siteSet.length == 0
        ) {
          return ElMessage({
            type: 'error',
            message: '广告版位不能为空',
          })
        }

        //赋值type
        state.dataList.userActionSets.forEach((item, index) => {
          if (item.zhuanHuaGy == 1) {
            item.dataBase.forEach((i) => {
              if (i.userActionSetId == item.id) {
                state.dataList.userActionSets[index].type = i.type
              }
            })
          }
        })

        //效验转化id
        state.dataList.mpConversions.forEach((item) => {
          if (item.conversionId == '' && state.dataList.isNewVersion == 1) {
            state.confirmType = false
            return ElMessage({
              type: 'error',
              message: '账户id：' + item.accountId + '的转化id不能为空',
            })
          }
        })

        //效验数据源
        state.dataList.userActionSets.forEach((item) => {
          if (
            item.zhuanHuaGy == 1 &&
            item.id == '' &&
            state.dataList.isNewVersion == 0
          ) {
            state.confirmType = false
            return ElMessage({
              type: 'error',
              message: '账户id：' + item.accountId + '的数据源不能为空',
            })
          }
        })


        //效验售卖方式
        state.dataList.strategyDtos.forEach((item) => {
          //先效验营销目为营销目的
          if(state.dataList.marketingGoal=='MARKETING_GOAL_PRODUCT_SALES'){
          if (
            item.shortPlayPayType == 'SHORT_PLAY_PAY_TYPE_CHARGE_PLAY' &&
            item.sellStrategyId == ''
          ) {
            state.confirmType = false
            return ElMessage({
              type: 'error',
              message: '账户id：' + item.accountId + '的付费售卖方式不能为空',
            })
          }
        }

        })

        if (state.dataList.timeFalg == 1) {
          //多时段数据
          state.dataList.times = state.weektimeData
        }

        if (state.dataList.timeDate.length > 0) {
          //指定时间投放
          state.dataList.beginDate = state.dataList.timeDate[0]
          state.dataList.endDate = state.dataList.timeDate[1]
        } else {
          return ElMessage({
            type: 'error',
            message: '指定开始和结束日期不能为空！',
          })
        }

        console.log(
          '%c [广告]-508',
          'font-size:13px; background:pink; color:#bf2c9f;',
          state.dataList
        )
        if (state.confirmType == true) {
          context.emit('getVertisementListFun', state.dataList)
        }
      },
    })
    const splicing = (list) => {
      let same
      let i = -1
      const len = list.length
      const arr = []

      if (!len) {
        return
      }
      while (++i < len) {
        const item = list[i]

        if (item.check) {
          if (item.check !== Boolean(same)) {
            arr.push(...['、', item.begin, '~', item.end])
          } else if (arr.length) {
            arr.pop()
            arr.push(item.end)
          }
        }
        same = Boolean(item.check)
      }
      console.log()
      arr.shift()

      return arr.join('')
    }

    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
      splicing,
      mult_timeRange,
      // selectClipLibraryAllFun,
    }
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 200px;
}

.zhgyTab {
  > div {
    margin-bottom: 10px;
  }
}

.el-input {
  width: 400px;
}
</style>
