<template>
  <!-- 菜单权限管理 -->
  <!-- 头条新增模板2.0版本 -->
  <div class="paddbox toutiaoAddModel">
    <div class="toutiaoAddModelTitle">
      <el-card>
        <div class="toutiaoAddModelTitleTop flex">
          <div>
            <el-button
              type="primary"
              :disabled="openSonviewAble"
              @click="upAccountFun()"
              >{{
                openSonviewAble == false ? '请选择子账户' : '已选择完成'
              }}</el-button
            >
          </div>
          <div>
            <div class="title">
              <el-tooltip
                class="item"
                effect="dark"
                :content="sonString"
                placement="right"
              >
                <div>已选:({{ accountIds.length }})</div>
              </el-tooltip>
            </div>
          </div>
          <div>
            <el-button type="danger" round @click="ResetPageFun()"
              >重置</el-button
            >
          </div>
        </div></el-card
      >
    </div>
    <div class="toutiaoAddModelcontent">
      <el-card>
        <div class="toutiaoAddModel-cardbox ">
          <el-row :gutter="18">
            <!-- 广告 -->
            <el-col :span="8"
              ><div class="grid-content ep-bg-purple" />
              <!-- 广告开始 -->
              <div class="toutiaoAddModel-carditem">
                <div class="defineHead">
                  <span>广告 </span>
                  <span @click="advertisingFun"
                    ><i
                      title="编辑"
                      class="cursor editIcon el-icon-edit disabled"
                    ></i
                  ></span>
                </div>
                <div class="defineBody">
                  <div class="mandatory">
                    <div class="settingBox">
                      <!-- <span class="s_label">计划名称:</span>
                      <span class="s_text">{{ adDto.name }}</span> -->
                    </div>
                  </div>
                </div>
              </div></el-col
            >
            <!-- 创意 -->
            <el-col :span="8"
              ><div class="grid-content ep-bg-purple" />
              <!-- 创意开始 -->
              <div class="toutiaoAddModel-carditem">
                <div class="defineHead">
                  <span>创意 </span>
                  <span @click="AdcreativesFun"
                    ><i
                      title="编辑"
                      class="cursor editIcon el-icon-edit disabled"
                    ></i
                  ></span>
                </div>
                <div class="defineBody">
                  <div class="mandatory">
                    <div class="settingBox">
                      <!-- <span class="s_label">计划名称:</span>
                      <span class="s_text">{{ adDto.name }}</span> -->
                    </div>
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
        <!-- 按钮 -->
        <div class="MPAddModelBtn flex">
          <button
            type="button"
            class="el-button el-button--default el-button--small"
            @click="createTencentPlanFun()"
          >
            <span> 保存数据并发布 </span>
          </button>
        </div>
      </el-card>
    </div>
    <!-- 选择子账户弹窗 -->
    <el-dialog v-model="openSonview">
      <insertViewMP
        @getAccountListFun="getSonAccountListFun"
        @getSelectSonAccountData="getSelectSonAccountData"
        @getCannelFun="getCannelFun"
      ></insertViewMP>
    </el-dialog>

    <!-- 广告弹窗 -->
    <el-drawer size="60%" v-model="openNewadvertisementviewAble" title="广告">
      <mpSetUpadvertisementV3
        :accountIdss="accountIds"
        :tencentAdgroups="tencentAdgroups"
        @getVertisementListFun="getVertisementListFun"
        @getVertisementCannelFun="getVertisementCannelFun"
      ></mpSetUpadvertisementV3>
    </el-drawer>

    <!-- 创意弹窗 -->
    <el-drawer size="70%" v-model="openNewAdcreativesviewAble" title="创意">
      <mpSetUpAdcreativesV3
        :automaticList="automaticList"
        :mpAdcreativesDto="mpAdcreativesDto"
        @getAdcreativesListFun="getAdcreativesListFun"
        @getAdcreativesCannelFun="getAdcreativesCannelFun"
      ></mpSetUpAdcreativesV3>
    </el-drawer>
  </div>
</template>

<script>
import { createMpPlan, createTencentPlan } from '@/request/new/batchManagement'
import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs, watch } from 'vue'
import insertViewMP from '@/components/batch/insertViewMP.vue'
import mpSetUpadvertisementV3 from '../MPvideoComponent/mpSetUpadvertisementV3.vue'
import mpSetUpAdcreativesV3 from '../MPvideoComponent/mpSetUpAdcreativesV3.vue'
export default {
  name: 'mpAddModel',
  components: {
    insertViewMP,
    mpSetUpadvertisementV3,
    mpSetUpAdcreativesV3,
  },
  setup() {
    const state = reactive({
      // 打开选择子账户的弹窗
      openSonview: false,
      // 选择子账户的按钮是否可用
      openSonviewAble: false,
      // 子账户的字符串
      sonString: '',
      //=============要提交的数据===============/////////////////////////////
      // 选中的子账户数据
      accountIds: [],

      //广告的数据
      tencentAdgroups: {},

      //创意的数据
      mpAdcreativesDto: [],

      //广告特定版位数据
      automaticList: {
        //微信朋友圈
        pyq: false,
        //微信视频号
        sph: false,
        //创意类型
        dynamicCreativeType:'DELIVERY_MODE_COMPONENT',
      },
      //检查发现广告有没有提交
      ggValue: false,

      //新建广告弹窗控制
      openNewadvertisementviewAble: false,
      //新建创意弹窗控制
      openNewAdcreativesviewAble: false,
      //新建广告弹窗控制变化
      openNewadvertisementviewAbleFun: () => {
        state.openNewadvertisementviewAble = !state.openNewadvertisementviewAble
      },
      //新建创意弹窗控制变化
      openNewAdcreativesviewAbleFun: () => {
        if (
          JSON.stringify(state.tencentAdgroups) == '{}' ||
          state.tencentAdgroups.adgroupName == ''
        ) {
          return ElMessage({
            type: 'error',
            message: '请先填充广告数据',
          })
        } else {
          state.openNewAdcreativesviewAble = !state.openNewAdcreativesviewAble
        }
      },
      // 选择子账户的按钮值的变化
      openSonviewAbleFun: () => {
        state.openSonviewAble = !state.openSonviewAble
      },
      // 接收组件的取消
      getCannelFun: () => {
        state.upAccountFun()
      },
      // 修改打开选择子账户的弹窗
      upAccountFun: () => {
        state.openSonview = !state.openSonview
      },

      // 接收组件的确定
      getSonAccountListFun: (data) => {
        state.accountIds = data
        state.openSonviewAbleFun()
        data.forEach((item, index) => {
          if (data.length - 1 == index) {
            state.sonString += item
          } else {
            state.sonString += item + '，'
          }
        })
        state.upAccountFun()
      },
      //广告
      advertisingFun: () => {
        //效验子账户
        if (state.accountIds.length == 0) {
          return ElMessage({
            type: 'error',
            message: '子账户不能为空',
          })
        }
        //调用广告组件
        state.openNewadvertisementviewAbleFun()
        //判断是否是新创建还是原来就有
        // if (state.tencentAdgroups.length == 0) {
        if (JSON.stringify(state.tencentAdgroups) == '{}') {
          
        //创意类型 DELIVERY_MODE_CUSTOMIZE 自定义创意 DELIVERY_MODE_COMPONENT  组件化创意
          state.tencentAdgroups.dynamicCreativeType = 'DELIVERY_MODE_COMPONENT'
          //营销目的: MARKETING_GOAL_PRODUCT_SALES商品销售
          state.tencentAdgroups.marketingGoal = 'MARKETING_GOAL_PRODUCT_SALES'
          //营销载体类型：MARKETING_CARRIER_TYPE_JUMP_PAGE 页面跳转
          state.tencentAdgroups.marketingCarrierType =
            'MARKETING_CARRIER_TYPE_JUMP_PAGE'
          //期望ROI
          state.tencentAdgroups.expectedRoi=''
          //推广产品：短剧
          state.tencentAdgroups.marketingTargetType = 'MARKETING_TARGET_TYPE_SHORT_DRAMA'
          //推广产品为微信小程序：原始id
          state.tencentAdgroups.marketingAssetOuterId='',
          //优化目标
          state.tencentAdgroups.optimizationGoal = ''
          //是否为新版 0旧版本 1新版本
          state.tencentAdgroups.isNewVersion = 0
          //深度优化目标
          state.tencentAdgroups.deepConversionBehaviorGoal = ''
          //深度目标出价
          state.tencentAdgroups.deepConversionBehaviorBid = ''
          //视频号校验
          state.tencentAdgroups.checkVideo = false
          

          //转化 id，包含优化目标，深度优化目标，数据上报方式，归因方式等信息
          const mpConversions = []
          //  数据源
          const userActionSets = []
          //推广产品数据
          const marketingTargetAssets = []
          //售卖策略数据
          const strategyDtos = []
          //定向包数据
          const targetingPackagesDtos = []
          state.accountIds.forEach((e) => {
            //推广产品
            marketingTargetAssets.push({
              //账户id
              accountId: e,
              //产品id
              marketingAssetId: '',
              //产品数据
              marketingAssetData: [],
            })

            //转化id子数据
            const mpConversionsItem = {
              //子账户
              accountId: e,
              //转化归因id
              conversionId: '',
              //转化归因数据
              dataBase: [],
            }
            mpConversions.push(mpConversionsItem)

            //数据源子数据
            userActionSets.push({
              //子账户
              accountId: e,
              //转化归因 0全网 1精准
              zhuanHuaGy: 1,
              //类型
              type: '',
              //数据源id
              id: '',
              //数据源
              dataBase: [],
            })

            //售卖策略
            strategyDtos.push({
              //账户id
              accountId: e,
              //售卖策略 id
              sellStrategyId: '',
              //售卖方式类型 SHORT_PLAY_PAY_TYPE_FREE_PLAY 免费剧 SHORT_PLAY_PAY_TYPE_CHARGE_PLAY 收费剧
              shortPlayPayType: 'SHORT_PLAY_PAY_TYPE_CHARGE_PLAY',
            })

            //定向包
            targetingPackagesDtos.push({
              //账户id
              accountId: e,
              //定向包id
              id: '',
              //定向包数据
              Packages: [],
            })
          })

          //插入转化数据
          state.tencentAdgroups.mpConversions = mpConversions
          //插入定向包数据
          state.tencentAdgroups.targetingPackages = targetingPackagesDtos
          //插入推广产品数据
          state.tencentAdgroups.marketingTargetAssets = marketingTargetAssets
          //插入售卖方式数据
          state.tencentAdgroups.strategyDtos = strategyDtos
          ///插入数据源
          state.tencentAdgroups.userActionSets = userActionSets
          // 是否开启自动版位功能
          state.tencentAdgroups.automaticSiteEnabled = false
          //广告版位
          state.tencentAdgroups.siteSet = [
            // 'SITE_SET_MOMENTS',
            // 'SITE_SET_WECHAT',
            // 'SITE_SET_WECHAT_PLUGIN',
            'SITE_SET_KANDIAN',
            'SITE_SET_QQ_MUSIC_GAME',
            'SITE_SET_TENCENT_NEWS',
            'SITE_SET_TENCENT_VIDEO',
          ]
          //出价方式  BID_MODE_CPM  cpm   BID_MODE_OCPM    ocpm
          state.tencentAdgroups.bidMode = 'BID_MODE_OCPM'
          // SMART_BID_TYPE_CUSTOM   稳定拿量  SMART_BID_TYPE_SYSTEMATIC最大转化
          //出价类型  BID_STRATEGY_AVERAGE_COST  稳定拿量   BID_STRATEGY_TARGET_COST 优先拿量  BID_STRATEGY_PRIORITY_CAP_COST 控制成本上限
          state.tencentAdgroups.smartBidType = 'SMART_BID_TYPE_CUSTOM'

          //出价 单位元
          state.tencentAdgroups.bidAmount = ''
          //一键起量
          state.tencentAdgroups.autoAcquisitionEnabled = false
          //起量预算
          state.tencentAdgroups.autoAcquisitionBudget = ''
          //起量类型 0不限 1指定日预算
          state.tencentAdgroups.dailyBudgetType = 0
          //起量预算
          state.tencentAdgroups.dailyBudget = ''
          //开始和结束时间数组
          state.tencentAdgroups.timeDate = []
          //开始时间
          state.tencentAdgroups.beginDate = ''
          //结束时间
          state.tencentAdgroups.endDate = ''
          //是否投放时段 0全天 1多时段
          state.tencentAdgroups.timeFalg = 0
          //是否指定指定首日开始时间
          state.tencentAdgroups.timeChecked = false
          //指定首日开始时间
          state.tencentAdgroups.firstDayBeginTime = ''
          //多时段数组
          state.tencentAdgroups.times = []

          // =============================下面是旧数据=====================

          //售卖方式
          state.tencentAdgroups.strategyDtos = strategyDtos

          //出价系数参数
          const bidAdjustment = {
            //出价系数 false关闭 true打开
            banweiFalg: true,
            //出价系数
            bidCoefficient: 1.0,
          }
          //  bidAdjustment = bidAdjustmentItem

          //深度转化优化滑块
          state.tencentAdgroups.deepConversionFalg = false
          //深度转化目标 只有视频号才有
          state.tencentAdgroups.deepConversionSpec = {
            //深度优化方式
            fs: '深度目标优化',
            //深度优化类型  DEEP_CONVERSION_BEHAVIOR 优化转化行为
            deepConversionType: 'DEEP_CONVERSION_BEHAVIOR',
            //深度目标优化
            deepGoal: '',
            //深度优化目标数据
            deepGoalList: [
              { value: 'OPTIMIZATIONGOAL_ECOMMERCE_ORDER', label: '下单' },
              {
                value: 'OPTIMIZATIONGOAL_PAGE_SCAN_CODE',
                label: '加企业微信客服',
              },
            ],
            //深度目标出价
            deepBidAmount: '',
          }

          //广告计划名称
          state.tencentAdgroups.adgroupName = ''
        }
      },
      //接收广告的数据
      getVertisementListFun: (data) => {
        console.log(
          '%c [ 接收广告的数据 ]-330',
          'font-size:13px; background:pink; color:#bf2c9f;',
          data
        )
        state.tencentAdgroups = data
        //检查版位是否有朋友圈或者视频号
        if (data.siteSet.includes('SITE_SET_MOMENTS')) {
          state.automaticList.pyq = true
        } else {
          state.automaticList.pyq = false
        }
        if (data.siteSet.includes('SITE_SET_CHANNELS')) {
          state.automaticList.sph = true
        } else {
          state.automaticList.sph = false
        }
        state.automaticList.dynamicCreativeType=data.dynamicCreativeType

        console.log(
          '%c [ 检查版位是否有朋友圈或者视频号 ]-494',
          'font-size:13px; background:pink; color:#bf2c9f;',
          state.automaticList
        )

        state.openNewadvertisementviewAbleFun()
      },
      //创意
      AdcreativesFun: () => {
        //效验子账户
        if (state.accountIds.length == 0) {
          return ElMessage({
            type: 'error',
            message: '子账户不能为空',
          })
        }
        //调用创意组件
        state.openNewAdcreativesviewAbleFun()
        // if (JSON.stringify(state.mpAdcreativesDto) == '{}') {
          if (state.mpAdcreativesDto.length == 0) {
            state.mpAdcreativesDto[0]={}
          //广告创意名称
          state.mpAdcreativesDto[0].adcreativeName = ''
          //  动态创意类型   DYNAMIC_CREATIVE_TYPE_COMMON手动指定  DYNAMIC_CREATIVE_TYPE_PROGRAM自动匹配
          state.mpAdcreativesDto[0].creativeType='DYNAMIC_CREATIVE_TYPE_COMMON'
            
          // //创意形式id   721竖版视频9:16    720横版视频16:9
          // state.mpAdcreativesDto.adcreativeTemplateId = 721
          //视频和创意形式
          state.mpAdcreativesDto[0].videoDtos = []
          //落地页信息
          const pageSpec = []
          //视频号数据
          state.mpAdcreativesDto[0].promotedObjects = []
          //浮沉卡片id
          state.mpAdcreativesDto[0].fuCengCardId = ''
          // 0 朋友圈文字链 1 图文链接
          state.mpAdcreativesDto[0].isFriendsTextOrImageText = 0
          //图文 id
          state.mpAdcreativesDto[0].imageTextId = ''

          //文案
          state.mpAdcreativesDto[0].textDtos = []

          state.accountIds.forEach((item, index) => {
            //视频号数据赋值
            state.mpAdcreativesDto[0].promotedObjects.push({
              accountId: item,
              userName: '',
              userNameData: [],
            })

            //文案赋值
            state.mpAdcreativesDto[0].textDtos.push({
              //子账户id
              accountId: item,
              //单账户文案
              texts: [],
            })

            //视频和创意形式
            state.mpAdcreativesDto[0].videoDtos.push({
              //子账户id
              accountId: item,
              //创意形式id   721竖版视频9:16    720横版视频16:9
              adcreativeTemplateId: 721,
              vids: [],
              vidslist: [],
              // 弹窗的值
              wt: false,
              //选中的标题组id
              selectTid: '',
              // 所在区域
              area: 'on',
            })

            const pageSpecItem = {
              //子账户id
              accountId: item,
              //小程序 id
              miniProgramId: '',
              //小程序路径
              miniProgramPath: '',
              //落地页类型   PAGE_TYPE_WECHAT_MINI_PROGRAM 微信小程序  PAGE_TYPE_WECHAT_CANVAS 微信原生推广页 PAGE_TYPE_OFFICIAL官方落地页
              pageType: 'PAGE_TYPE_WECHAT_MINI_PROGRAM',
              //原生落地页id和官方落地页id（共用）
              pageId: '',
              //原生落地页集合
              pageIdList: [],
              //官方落地页集合
              officialPageIdList: [],
            }
            //
            const LinkSpecItem = {
              //链接名称类型
              linkNameType: '',
              //跳转落地页   微信小程序PAGE_TYPE_WECHAT_MINI_PROGRAM
              linkPageType: 'PAGE_TYPE_WECHAT_MINI_PROGRAM',
              //跳转落地页开关 false关闭 true开启
              linkSpecFlag: true,
              //文字链文案数组
              selectPengyouQuanList: [],
            }
            pageSpecItem.mpLinkSpecDto = LinkSpecItem
            pageSpec.push(pageSpecItem)
          })
          state.mpAdcreativesDto[0].pageSpec = pageSpec

          // //文案
          // state.mpAdcreativesDto.texts = []
          //品牌
          const BrandItem = {
            //品牌状态
            state: false,
            //品牌数据
            brandData: [],
            //选中的品牌标识
            brandLogo: {},
            //品牌id
            brandId: '',
            //品牌按钮
            buttonText: '查看详情',
            //品牌描述信息
            brandDesc: '',
            //品牌按钮数据
            buttonTextData: [],
          }
          state.mpAdcreativesDto[0].mpBrandDto = BrandItem

          //浮沉卡片
          const FuCengCardItem = {
            //卡片状态
            state: false,
            //卡片数据
            FuCengCardData: [],
            //选中的卡片标识
            FuCengCardLogo: {},
            //卡片id
            FuCengCardId: '',
            //卡片按钮
            buttonText: '',
            //卡片描述信息
            FuCengCardDesc: '',
            //卡片按钮数据
            buttonTextData: [],
          }
          state.mpAdcreativesDto[0].FuCengCard = FuCengCardItem

          //图文链接
          const imageTextItem = {
            //图文链接状态
            state: false,
            //图文链接数据
            imageTextData: [],
            //选中的卡片标识
            imageTextLogo: {},
            //图文id
            imageTextId: '',
            // //图文链接按钮
            // buttonText: '',
            // //卡片描述信息
            // FuCengCardDesc: '',
            // //卡片按钮数据
            // buttonTextData: [],
          }
          state.mpAdcreativesDto[0].imageText = imageTextItem

          //选中的视频id 最多十个
          state.mpAdcreativesDto[0].selectVideoData=[]
          state.mpAdcreativesDto[0].vids = []
          state.mpAdcreativesDto[0].vidslist = []
          // 弹窗的值
          state.mpAdcreativesDto[0].wt = false
          // 所在区域
          // state.mpAdcreativesDto[0].area = 'on'
          //选中的标题组id
          state.mpAdcreativesDto[0].selectTid = ''

        }
      },
      // 接收创意的数据
      getAdcreativesListFun: (data) => {
        console.log(
          '%c [ 接收创意的数据 ]-464',
          'font-size:13px; background:pink; color:#bf2c9f;',
          data
        )
        state.mpAdcreativesDto = data
        state.openNewAdcreativesviewAbleFun()
        // state.openNewAdcreativesviewAble = false
      },
      //效验是否选了子账户
      inspectSonAccount: () => {
        if (state.accountIds.length == 0) {
          ElMessage({
            type: 'error',
            message: '请选择子账户',
          })
        }
      },
      // 重置
      ResetPageFun: () => {
        location.reload()
      },
      //保存数据并发布
      createTencentPlanFun: () => {
        //子账户效验
        if (state.accountIds.length == 0) {
          return ElMessage({
            type: 'error',
            message: '子账户不能为空',
          })
        }
        //广告效验
        if (
          JSON.stringify(state.tencentAdgroups) == '{}' ||
          state.tencentAdgroups.adgroupName == ''
        ) {
          return ElMessage({
            type: 'error',
            message: '广告数据不能为空',
          })
        }
        //打印广告计划数据
        console.log(
          '%c [广告]-508',
          'font-size:13px; background:pink; color:#bf2c9f;',
          state.tencentAdgroups
        )

                //打印创意效验数据
                console.log(
          '%c [创意]-508',
          'font-size:13px; background:pink; color:#bf2c9f;',
          state.mpAdcreativesDto
        )
        //创意效验  
        if (
          JSON.stringify(state.mpAdcreativesDto) == '{}' ||
                    // state.mpAdcreativesDto ||
          state.mpAdcreativesDto[0].adcreativeName == ''
        ) {
                    return ElMessage({
            type: 'error',
            message: '创意数据不能为空',
          })
        }

        const datas = {
          accountIds: state.accountIds,
          tencentAdgroups: state.tencentAdgroups,
        }
        if(state.automaticList.dynamicCreativeType=='DELIVERY_MODE_COMPONENT'){
          //组件化创意
          datas.tencentDynamicCreativeList=state.mpAdcreativesDto
        }else{
          //自定义创意
          datas.tencentDynamicCreative=state.mpAdcreativesDto[0]
        }
        createTencentPlan(datas).then((res) => {
          ElMessage({
            duration: 5000,
            type: 'success',
            message: '创建成功，详细信息在MP创建返回信息查看',
          })
        })
      },
    })

    // const selectClipLibraryAllFun = () => {
    //   console.log('state.day', state.day)
    //   const datas = {
    //     currenPage: state.currentPage,
    //     size: state.pagesize,
    //     createTime: state.day,
    //     accountId: state.accountId2,
    //   }
    //   selectClipLibraryAll(datas).then((res) => {
    //     state.currentPage = res.data.currenPage
    //     state.total = res.data.totalSize
    //     state.tableData = res.data.data
    //   })
    // }

    // selectClipLibraryAllFun()
    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
      // selectClipLibraryAllFun,
    }
  },
}
</script>

<style lang="scss" scoped>
.toutiaoAddModelcontent {
  margin-top: 20px;
}
.toutiaoAddModel-carditem {
  width: 100%;
  font-size: 14px;
  border: 1px solid #e2e6f0;
  border-radius: 4px;
  margin-top: 0 !important;
  margin-right: 20px;
}
.defineHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f7fa;
  border-radius: 3px 3px 0 0;
  color: #282c33;
  padding: 12px 16px;
  box-sizing: border-box;
}

.defineBody {
  width: 100%;
  height: 400px;
  padding: 6px 12px;
  overflow: auto;
  box-sizing: border-box;
}
.toutiaoAddModelTitleTop {
  > div {
    margin-right: 10px;
  }
}

.item {
  padding-top: 10px;
}

.MPAddModelBtn {
  margin-top: 10px;
  justify-content: flex-end;
}
</style>
